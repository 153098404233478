import * as React from 'react';
import {
  Faqs,
  Hero,
  Cards,
  Layout,
  Friends,
  Rewards,
  Airtime,
  Interest,
  Testimonials,
  ApiResponseLayout,
} from '../components';
import { useStrapiStore } from '../store/useStrapiStore';

const Home = () => {
  const { homepageData, homepageState } = useStrapiStore((state) => ({
    homepageData: state.homepageData,
    homepageState: state.homepageState,
  }));

  if (homepageState === 'loading')
    return <ApiResponseLayout apiResponse="loading" />;

  if (!homepageData || !homepageData.data || homepageState === 'error') {
    return <ApiResponseLayout apiResponse="error" />;
  }

  const {
    hero_text_1,
    sub_hero_text_1,
    loan_offer_header,
    hero_text_2,
    sub_hero_text_2,
    hero_text_3,
    sub_hero_text_3,
    hero_text_4,
    sub_hero_text_4,
    hero_text_5,
    sub_hero_text_5,
  } = homepageData.data.attributes;

  return (
    <Layout
      title="Irorun | Your easy access to urgent loans"
      description="Need cash urgently? Get up to N50,000 quickly from Irorun to meet that urgent need, with low interest rates and no hassle."
    >
      <div className="home">
        <Hero heroText={hero_text_1} subHeroText={sub_hero_text_1} />
        <Cards loanOfferHeader={loan_offer_header} />
        <Rewards heroText={hero_text_2} subHeroText={sub_hero_text_2} />
        <Interest heroText={hero_text_3} subHeroText={sub_hero_text_3} />
        <Airtime heroText={hero_text_4} subHeroText={sub_hero_text_4} />
        <Friends heroText={hero_text_5} subHeroText={sub_hero_text_5} />
        <Testimonials />
        <Faqs />
      </div>
    </Layout>
  );
};

export default Home;
